@media (max-width: 1399.98px) {
  .bet_status .bet-sec .form-group .form-control {
    font-size: 13px;
    padding: 6px 6px;
  }
  .nav-link {
    font-size: 12px;
    color: #fff;
    padding: 10px 5px 5px;
}
  .bet_status .bet-sec .form-select {
    font-size: 12px;
    padding: 5px 8px;
  }
  .total-balance-sec ul li strong {
    font-size: 14px;
  }
  .common-tab .nav-tabs .nav-item {
    margin-bottom: 9px;
  }
  .main-header .navbar-nav .nav-link {
    padding: 6px 5px;
    font-size: 11px;
  }
  /* .top-header .container,
  .main-header .container {
    max-width: 100%;
  } */
  .transaction-history .table {
    width: 100%;
  }
  .account-table.batting-table table {
    width: 1200px;
  }
  .betting-profile-sec form {
    flex: 0 0 33%;
    max-width: 37%;
    justify-content: end;
  }
}
@media (max-width: 1199px) {
  .main-header .navbar-collapse {
    position: absolute;
    top: 57px;
    background: #000;
    width: 100%;
    z-index: 2;
    left: 0;
    right: 0;
  }
  .css-sggven{
  min-width: 250px !important;
  }
  .css-3l3i23 {
    padding: 1px 40px !important;
}
  .total-balance-sec ul li {
    padding: 0px 10px;
  }
  .account-table .table {
    min-width: 850px;
  }
  .main-header .navbar-nav .nav-link {
    padding: 12px 16px;
    font-size: 14px;
  }
  .main-header .navbar-toggler {
    padding: 4px 7px;
    background: #000;
    font-size: 13px;
  }
  .main-header .navbar-toggler-icon {
    filter: brightness(0) invert(1);
    opacity: 1;
  }

  .total-balance-sec ul li strong {
    font-size: 12px;
  }
  .bet_status .bet-sec .form-group input[type="date"] {
    font-size: 11px;
  }
  .main-header .navbar-nav .nav-link {
    padding: 8px 8px;
    font-size: 13px;
  }
  .main-header .dropdown-item {
    font-size: 13px;
  }
  .main-header-time-zone li {
    display: inline-block;
    margin-right: 7px;
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .sidebar .sidebar-main ul li a {
    font-size: 13px;
  }
  .total-balance-summary strong {
    font-size: 30px;
  }

  .sidebar {
    position: fixed;
    left: -231px;
    top: 0;
    bottom: 0;
    width: 210px;
    height: 100%;
    background: #fff;
    overflow-y: auto;
    padding-top: 29px;
    transition: 0.4s;
  }
  .find-member-director .btn {
    font-size: 14px;
  }

  .main-header .navbar-nav .nav-link:hover {
    color: #ffffff;
  }
  .total-balance-sec ul {
    flex-wrap: wrap;
  }
  .total-balance-sec ul li {
    display: inline-block;
    max-width: 33.33%;
    flex: 0 0 33.33%;
    border-left: none;
    padding: 10px 0px;
  }
  /**=================RR=============**/
  .setting_dashboard_block > ul > li {
    flex: 0 0 20%;
    max-width: calc(20% - 12px);
  }
  .banking_detail_table td .form-control {
    width: 100%;
  }
}
@media (max-width: 820px) {
.css-sggven{
  min-width: 180px !important;
  }
  .css-3l3i23 {
    padding: 1px 20px !important;
}}
@media (max-width: 767px) {
  .breadcum-sec marquee {
    width: 68%;
  }

  .navbar-nav .nav-link.active,
.navbar-nav .nav-link.show,
.dropdown-item:hover {
  color: #000 !important;
  background: lightskyblue;
}
  .find-member-sec .small_form_control {
    width: 100%;
  }
  .sub-table td:first-child {
    padding-left: 10px !important;
  }
  .account-table.batting-table table {
    width: 100%;
  }
  .profit_loss_table .table-commission {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .account-table .table {
    min-width: 100% !important;
  }
  .gray-inner_table {
    padding: 0px !important;
  }

  .risk-management-table-new .account-table .table {
    width: 100% !important;
    min-width: 100% !important;
  }
  .dw-graph-position {
    margin: 5px;
  }
  .back-all-size.back-all-size {
    padding: 8px !important;
    font-size: 10px !important;
  }
  .light-blue {
    padding: 6px;
    font-size: 11px !important;
    line-height: normal !important;
  }
  .selection-table td {
    border: none !important;
  }
  .lay-all {
    padding: 6px;
    font-size: 11px !important;
    line-height: normal !important;
  }
  .selection-table td {
    border: 1px solid #7e97a7 !important;
    padding: 2px 5px;
  }
  .dw-toggle {
    width: 102px;
    height: 30px;
    margin-right: 5px;
  }
  .find-member-sec.search_banking_detail .d-flex {
    margin-bottom: 5px !important;
  }
  .dw-value_text_box .form-control {
    height: 100%;
  }
  .detail-slip {
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 8px !important;
  }
  .detail-slip span {
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .detail-slip span:first-child {
    margin-right: 0 !important;
  }
  .db-sec {
    padding: 5px;
    margin-bottom: 2px !important;
  }
  .bet_status .bet-sec .form-group input[type="date"] {
    flex: 0 0 100%;
    max-width: 100% !important;
    margin-right: 0 !important;
    font-size: 14px;
  }
  /* .modal-content {
  padding: 1px !important;
} */
  .accout_cols_outer {
    display: flex;
    flex-direction: column;
  }
  .left_side {
    max-width: 100%;
  }
  .right_side {
    padding-left: 0 !important;
  }
  .select-active-list {
    width: 75%;
  }
  /* .analysis-running-market table tr td, .analysis-running-market table tr th {
    padding: 5px !important;
} */
  .sport-anaylsis {
    padding: 0 !important;
    margin: 0 !important;
  }
  .bookmakerbs .bid {
    width: 100% !important;
  }
  .toppadding span {
    font-size: 12px !important;
  }
  .toptitle {
    font-size: 12px !important;
  }
  .bk-btn {
    font-size: 10px !important;
  }
  .bookmakerbs .ask {
    width: 100% !important;
  }
  .sport-anaylsis-inner {
    padding: 3px !important;
  }
  .betlist-n .bet-period input.form-control {
    max-width: 100% !important;
    width: 100% !important;
  }
  .history-btn {
    margin-top: 15px;
    width: 100%;
    text-align: center;
  }
  .betList-bottom {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-bottom: 0;
  }
  .betting-profile-sec form {
    flex: 1 1;
    max-width: 56%;
    justify-content: start;
    width: 100%;
  }
  .betting-profile-sec {
    flex-direction: column;
    align-items: normal !important;
  }
  .find-member-sec form .d-flex {
    width: 50%;
  }
  /**================RR===============**/
  .paymoney_form .form-control {
    max-width: 150px;
  }
  .paymoney_form .btn {
    padding: 7px 10px;
  }

  .setting_dashboard_block > ul > li {
    flex: 0 0 25%;
    max-width: calc(25% - 12px);
  }

  /*new 03/11/23*/

  .find-member-director.text-xl-end {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: end;
  }
  .top_player-wrap {
    width: 100% !important;
  }

  .top_player-wrap ul {
    width: 100% !important;
  }
  .find-member-sec form .d-flex {
    width: 86% !important;
  }
  .find-member-sec form#searchForm .btn {
    display: flex;
    justify-content: end;
    position: absolute;
    right: 0px;
    top: 46px;
  }

  .find-member-sec form#searchForm .btn.search-btn {
    display: block;
    justify-content: end;
    position: absolute;
    right: 4px;
    top: 18px;
  }

  .find-member-sec form {
    width: 100%;
    display: block;
    position: relative;
  }
  .paymoney_form .btn,
  .paymoney_form .form-control {
    margin: 10px 0;
    width: 100% !important;
    max-width: 100% !important;
  }

  .find-member-sec form {
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: end !important;
  }
  .main-header {
    padding: 5px;
  }

  form.super-admin-form {
    padding: 10px 0px;
  }
  .super-admin-form .form-control,
  .super-admin-form .form-select {
    max-width: 100%;
  }
  .super-admin-form .form-label {
    text-align: left;
  }
  .change-password-sec label {
    flex: 0 0 0px;
    text-align: left;
    display: inline-block;
    width: 100%;
    max-width: 100%;
  }
  .find-member-sec form .d-flex {
    width: 100% !important;
  }
  .find-member-sec.search_banking_detail {
    width: 100%;
  }
  .find-member-sec.search_banking_detail .d-flex {
    margin-bottom: 10px;
    margin-right: 0px !important;
  }
  .sidebar {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow-y: auto;
    padding: 0;
    transition: 0.4s;
    margin: 0;
    left: 0;
    margin-bottom: 20px;
  }
  .sidebar ul {
    margin: 0;
  }

  .main-center {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .login_box {
    overflow: hidden;
    display: block;
  }
  .login_left {
    flex: 0 0;
    position: relative;
    height: 250px;
    overflow: hidden;
  }
  .login_left figure {
    text-align: center;
  }

  .login_logo {
    padding: 20px;
  }

  /*=== 09/02/24 ===*/

  .dashboard-header {
    box-shadow: 2px 3px 16px 8px rgba(0, 0, 0, 0.24);
    max-width: 98%;
    margin: auto;
    padding: 12px 0 !important;
    background: #fff;
    border-radius: 6px;
  }

  .dashboard-header .col-sm-6 {
    width: 50% !important;
  }

  .dashboard-header .col-sm-6 span {
    margin-bottom: 3px;
    display: inline-block;
  }

  .dashboard-header button {
    margin-top: 10px;
    border-radius: 6px !important;
    padding: 7px 20px;
  }

  .dashboard-single {
    border-radius: 4px;
  }

  .find-member-director.text-xl-end {
    margin-top: 0;
    padding-right: 5px;
  }

  section.find-member-sec.py-3 {
    padding-bottom: 0 !important;
  }

  .header-slip span {
    font-size: 13px;
    color: #000;
    line-height: 28px;
  }
  .top-header {
    padding: 0 !important;
  }
  .top-header .logo {
    text-align: left !important;
    margin-bottom: 0 !important;
    margin-top: 2px;
  }

  .navbar-toggler-icon {
    filter: invert(1);
  }

  .top-header .col-7 {
    order: 2;
  }
  .top-header .col-1 {
    order: 1;
  }
  .top-header .col-4 {
    order: 3;
  }

  .navbar .navbar-toggler {
    padding: 0;
  }
  .navbar {
    padding: 0;
    margin-left: -15px;
    width: 32px;
    margin-top: 5px;
  }
  .navbar-collapse {
    height: calc(100vh - 44px);
    width: 270px;
    background:#16a7c0;
    left: -10px;
    position: absolute;
    top: 39px;
    border: #16a7c0 thin solid;
    overflow: auto;
  }
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }

  .header-slip {
    margin-top: 44px;
  }

  .nav-link {
    font-size: 14px;
    color: #000;
    border-bottom: #069f72 thin solid;
    padding: 8px 10px 12px 15px;
  }

  .nav-link:hover {
    background: #068397;
  }

  header .dropdown-toggle {
    margin-top: 3px;
    padding-right: 0;
  }

  /* .navbar-nav .nav-link.active,
  .navbar-nav .nav-link.show,
  .dropdown-item:hover {
    color: rgb(0 0 0) !important;
  } */

  .navbar-nav .dropdown-menu {
    position: static;
    background: white;
    font-size: 14px;
    margin: 0;
    border: none;
  }
  .nav-link:hover,
  .nav-link:focus {
    font-size: 13px !important;
    color: rgb(0 0 0) !important;
  }

  .navbar-collapse .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
    position: absolute;
    right: 16px;
    top: 16px;
  }
.text-green{color:green !important;}
.text-black{color:black !important;}
  .betlist-n {
    display: block;
    margin: 0;
    border: none;
  }

  .betlist-n .bet-sec {
    padding-bottom: 0;
    display: block !important;
  }

  .betlist-n .bet-sec label.form-label {
    padding: 0 !important;
    margin: 0 0 3px 0 !important;
  }
  .betlist-n .bet-sec select {
    min-width: 120px;
    max-width: 100%;
    width: 100%;
  }
  .bet_status {
    position: relative;
    padding: 10px;
    /* background-color: #e0e6e6; */
    /* border-bottom: 1px solid #7e97a7; */
    margin-bottom: 10px;
}
  .betlist_btn {
    text-align: center;
    padding: 10px;
    justify-content: center;
  }
  .batting-table-scroll {
    overflow: auto;
  }

  .batting-table-scroll .table th {
    color: #fff;
    background-color: #000 !important;
    border: 1px solid #7e97a7;
    border-width: 1px 0;
    padding: 8px 10px;
    font-size: 10px;
    font-weight: 600;
    min-width: 50px;
  }
  /* .account-table .table {
  min-width: 100%;
} */
  .batting-table-scroll .table td {
    font-size: 10px;
  }

  .batting-table-scroll .table > :not(caption) > * > * {
    background-color: transparent;
  }

  .find-member-sec form input.form-control {
    padding: 6px 10px;
    max-width: 100%;
    border-radius: 4px;
  }
  .find-member-director .btn:last-child {
    margin: 0;
    height: 36px;
    width: 100%;
  }

  .risk-pop.account-table .table {
    width: 100%;
  }
  .risk-pop.account-table .table th {
    min-width: 120px;
    font-size: 12px;
  }

  .risk-management-table .account-table .table {
    width: 800px;
  }
  .account-table .table th {
    min-width: 70px;
    font-size: 13px;
  }

  .aprofit-downline td {
    width: auto;
  }
  .bet_status .bet-sec .form-group input[type="date"] {
    height: 36px;
  }
  .batting-table-scroll .table th {
    min-width: 100px;
  }

  .client-tabel td.action_link.text-end {
    min-width: 190px;
  }
  .mobile-hide {
    display: none;
  }
  .game-wrap table {
    min-width: 360px;
  }
  .analysis-running-market div.runningf.in-play-title {
    width: auto !important;
  }
  .game-wrap .fancytable .w-16 {
    width: 100px;
  }
  .responsive {
    overflow: auto;
  }
  .responsive .all-bets-dialog-tabel {
    min-width: 1210px;
  }
  .border-top1 {
    border-top: #d9d7d7 thin solid;
    margin-bottom: 1px;
    padding-top: 2px;
  }

  /* .find-member-director .btn,
  .theme_light_btn {
    background-color: #000 !important;
    box-shadow: none;
    font-size: 12px;
    line-height: 18px !important;
    border: 1px solid #031621 !important;
    font-weight: 400 !important;
    background-image: none !important;
    color: #fff !important;
    padding: 5px 15px !important;
    height: auto !important;
  }
 
  .theme_dark_btn:hover,
  .green-btn:hover {
    background: #1acae6 !important;
    color: #333;
    border-color: #0f90a3;
  } */

  .theme_dark_btn,
  .search-btn,
  .green-btn {
    box-shadow: none;
    font-weight: bold;
    color: #fff !important;
    font-size: 12px;
    border-radius: 5px;
    /* background-color: #09bd88; */
    /* background-image: linear-gradient(#09bd88, #006d4e); */
    /* border: 1px solid #09bd88; */
    padding: 5px 10px;
    line-height: 20px;
  }

  .theme_dark_btn:hover,
  .search-btn:hover,
  .green-btn:hover {
    color: #fff;
    background-color: lightskyblue;
    /* background-image: linear-gradient(#545454, #000); */
    border: 1px solid #000;
  }

  .match-tbody .green-btn {
    padding: 5px 10px;
  }

  .theme_light_btn {
    padding: 5px 10px;
  }

  .form-check-input:checked {
    background-color: #1acae6;
    border-color: #1acae6;
  }
  .casino-control div {
    margin-bottom: 10px;
  }
  .analysis-running-market .top {
    background-color: #000;
  }
  .bg-dark1 {
    background-color: #1acae6 !important;
    color: #fff !important;
  }
  .dw-value_text_box {
    position: relative;
    margin: 0px;
  }
  .modal-2xl .modal-header {
    padding: 5px 0 5px 10px !important;
    background: #000;
  }
  .search_banking_detail input.form-control {
    padding-left: 13px !important;
  }
  .s-btn{position: absolute;
    top: 0;
    right: 0;
    height: 34px;
    background: #333;
  }




  .find-member-director .btn:hover,
  .theme_light_btn:hover {
    background-color: #000 !important;
    border: 1px solid #000 !important;
    background-image: none !important;
    color: #fff !important;
  }
}

@media (max-width: 575px) {
  .change-status-form .green-btn {
    margin-top: 20px;
  }

  .top-header .logo {
    text-align: center;
    margin-bottom: 9px;
  }
  .bet_status .bet-sec {
    flex-direction: column;
    align-items: normal;
  }
  .bet_status .bet-sec .form-label {
    margin-bottom: 10px !important;
  }
  .block-modal-content h3 {
    font-size: 15px;
  }
  .block-search-sec .btn {
    font-size: 13px;
  }
  .change-password-sec .d-flex {
    flex-direction: column;
    align-items: start !important;
  }

  .change-password-sec .d-flex label {
    text-align: left;
    margin-bottom: 10px;
  }
  .top_player-wrap table {
    width: 455px;
  }

  .top_player-wrap .responsive {
    overflow-x: auto;
  }
  .top_player-wrap ul {
    display: flex;
    flex-wrap: nowrap;
    width: 459px;
    overflow-x: auto;
  }
  .match-sec-sroller {
    overflow-x: auto;
  }
  .agent-path li a strong {
    font-size: 15px;
  }
  .common-tab .nav-tabs .nav-item .nav-link {
    padding: 4px 15px 4px;
    font-size: 13px;
  }
  .exposure-content table td {
    font-size: 12px;
  }
  .changestatus-option ul li {
    max-width: calc(33.33% - 16px);
    margin: 0px 8px;
    padding: 6px;
    font-size: 12px;
  }
  .change-status-form form .f-group {
    margin-bottom: 11px;
    flex: 1;
    max-width: 100%;
  }

 .change-status-modal  .modal-dialog, .super-admin-modal .modal-dialog{
    transform: none;
    top: 15%;
}


  .change-status-form form {
    flex-direction: column;
  }
  .changestatus-option {
    padding: 22px 9px;
  }
  .total-balance-summary strong {
    font-size: 22px;
  }
  .total-balance-summary dt {
    font-size: 15px;
  }
  .betting-profile-sec form {
    flex: 1 1;
    max-width: 87%;
  }
  .history-btn li a {
    font-size: 13px;
    padding: 5px 6px;
  }
  .history-btn li {
    margin-right: 7px;
    margin-bottom: 9px;
  }
  .bet_status .bet-sec label.form-label,
  .bet_status .bet-sec.bet-period .form-label {
    flex: 0 0 1%;
    max-width: 100%;
    padding: 0px 4px;
  }
  .bet_status .bet-sec.bet-period .form-group {
    flex: 0 0 0%;
    max-width: 100%;
  }
  .breadcum-sec .bredcum-sec-main {
    padding: 8px 22px;
    border-radius: 0;
    margin: 0px -12px;
  }
  .main-header-time-zone li {
    margin-right: 5px;
    font-size: 14px;
  }
  .breadcum-sec marquee a {
    font-size: 14px;
  }
  .top-header {
    padding: 9px 0px;
  }
  .find-member-sec form {
    flex-direction: column;
    width: 100%;
  }
  .find-member-sec form .position-relative {
    margin-bottom: 10px;
    width: 100%;
  }
  .total-balance-sec ul {
    padding: 13px 11px;
  }
  .total-balance-sec ul li strong {
    font-size: 13px;
  }
  .total-balance-sec ul li dt {
    font-size: 11px;
  }
  .find-member-sec form .d-flex {
    width: 100%;
  }
  .total-balance-sec ul li {
    flex: 0 0 50%;
    padding: 6px 0px;
    border-bottom: 1px solid #0000002b;
    max-width: 50%;
  }
  /**==============RR==========**/
  .paymoney_form .btn,
  .paymoney_form .form-control {
    margin: 10px 0;
    width: 200px;
    max-width: 200px;
  }
  .paymoney_form .btn {
    margin: 0;
  }
  .bank_balance_detail h6 {
    margin: right 0;
    margin-bottom: 10px;
    border-right: none;
    width: auto;
    display: inline-block;
}

  .setting_dashboard_block > ul > li {
    flex: 0 0 33.333%;
    max-width: calc(33.333% - 12px);
  }
}

@media (max-width: 479px) {
  .setting_dashboard_block > ul > li {
    flex: 0 0 50%;
    max-width: calc(50% - 12px);
  }
}

.modal-2xl {
  --bs-modal-width: 90%;
}

.modal-2xl .modal-content {
  padding: 0px;
}
.modal-2xl .form-label {
  font-weight: bold;
  opacity: 0.7;
}
.modal-2xl .btn-close {
  top: 0px;
  right: 15px;
  color: #fff;
  filter: invert(1);
}

.modal-2xl .modal-header {
  padding: 5px 0 5px 10px !important;
  background: #000;
}
.modal-2xl .modal-title-status {
  font-size: 15px;
  color: #fff;
  font-weight: bold;
}
.all-bets-dialog-tabel.table th {
  color: #fff;
  background-color: #140c34;
}
.all-bets-dialog-tabel tr td {
  padding: 0.5rem 0.5rem;
}


@media (max-width:350px)
{
  .top-header .col-7 {
    order: 2;
    width: 57%;
}
.navbar-toggler-icon {
  width: 1.2em;
  height: 1.2em;
}

.header-slip {
  margin-top: 38px;
}
.logo img{max-width: 110px !important}
.nav-link {
  padding: 8px 5px 8px 15px;
}



}